import { Flex, Text, useTheme } from '@chakra-ui/react';
import { MouseoverTooltip } from '~/app/components/tooltips/MouseOverTooltip';
interface TooltipLabelProps {
  icon: React.ElementType;
  tooltipText: string;
  iconSize?: string | number;
  color?: string;
  label?: string;
}
const TooltipLabel: React.FC<TooltipLabelProps> = ({
  icon: Icon,
  tooltipText,
  iconSize = '14px',
  color = 'highlight',
  label,
  ...rest
}) => {
  return <Flex align="flex-start" w="100%" justify="flex-start" cursor="pointer" {...rest} data-sentry-element="Flex" data-sentry-component="TooltipLabel" data-sentry-source-file="Label.tsx">
      <MouseoverTooltip text={<Text>{tooltipText}</Text>} data-sentry-element="MouseoverTooltip" data-sentry-source-file="Label.tsx">
        <Flex align="center" justify="flex-start" w="100%" data-sentry-element="Flex" data-sentry-source-file="Label.tsx">
          <Icon color={color} size={iconSize} data-sentry-element="Icon" data-sentry-source-file="Label.tsx" />
          <Text ml="2" textAlign="left" fontSize="16px" userSelect="none" zIndex={1} data-sentry-element="Text" data-sentry-source-file="Label.tsx">
            {label}
          </Text>
        </Flex>
      </MouseoverTooltip>
    </Flex>;
};
export default TooltipLabel;