'use client';

import { Box, Text, Flex, useDisclosure } from '@chakra-ui/react';
import { MenuIcon, ChevronLeft } from 'lucide-react';
import { useLocale, useTranslations } from 'next-intl';
import Gas from '~/app/components/settings/components/Gas';
import Slippage from '~/app/components/settings/components/Slippage';
import Deadline from '~/app/components/settings/components/Deadline';
import ExpertMode from '~/app/components/settings/components/ExpertMode';
import SwapChartToggle from '~/app/components/settings/components/SwapChartToggle';
function AppSettings() {
  const t = useTranslations();
  return <>
      <Box p={3} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <Text fontSize="20px" fontWeight="500" mb={1} data-sentry-element="Text" data-sentry-source-file="index.tsx">
          {t('App Settings')}
        </Text>
        <Flex direction="column" justify="center" align="center" p="4" bg="alphaGradient.100" boxShadow="popover.xs" borderRadius="12px" gap="2" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
          <Gas data-sentry-element="Gas" data-sentry-source-file="index.tsx" />
          <Slippage data-sentry-element="Slippage" data-sentry-source-file="index.tsx" />
          <Deadline data-sentry-element="Deadline" data-sentry-source-file="index.tsx" />
          <ExpertMode data-sentry-element="ExpertMode" data-sentry-source-file="index.tsx" />
          <SwapChartToggle data-sentry-element="SwapChartToggle" data-sentry-source-file="index.tsx" />
        </Flex>
      </Box>
    </>;
}
export default AppSettings;