import { Flex, Heading, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import MotionCard from '~/app/components/card/MotionCard';
const FeaturedPoolsCard = ({
  type,
  delay,
  children
}) => {
  const typeStyles = {
    dark: {
      bg: 'insetComponent',
      borderColor: 'text'
    },
    light: {
      bg: 'insetComponent',
      borderColor: 'text'
    }
  };
  return <MotionCard hover={0.98} tap={0.98} data-sentry-element="MotionCard" data-sentry-component="FeaturedPoolsCard" data-sentry-source-file="index.tsx">
      <Flex w="340px" h="275px" direction="column" align="center" bg={typeStyles[type].bg} border="1px solid" borderColor={typeStyles[type].borderColor} borderRadius="18px" p="2" cursor="pointer" boxShadow="insetComponentShadow" data-aos="zoom-in-right" data-aos-delay={delay} data-aos-anchor="[data-aos-id-featuredpools]" data-aos-mirror="true" data-aos-anchor-placement="top-bottom" data-aos-offset="100" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        {children}
      </Flex>
    </MotionCard>;
};
FeaturedPoolsCard.propTypes = {
  type: PropTypes.oneOf(['dark', 'light']).isRequired,
  delay: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
export default FeaturedPoolsCard;