import {
  enUS,
  et,
  zhCN,
  es,
  fr,
  de,
  hi,
  ja,
  ru,
  tr,
  ptBR,
  pt,
  uk,
  vi,
  id,
  it,
  ko,
  Locale,
} from 'date-fns/locale';

export interface SupportedLanguageType {
  code: string;
  name: string;
  dateFnsLocale: Locale;
}

export const supportedLanguages: SupportedLanguageType[] = [
  { code: 'en', name: 'English', dateFnsLocale: enUS },
  { code: 'zh', name: '简体中文 (Chinese)', dateFnsLocale: zhCN },
  { code: 'fr', name: 'Français (French)', dateFnsLocale: fr },
  { code: 'de', name: 'Deutsch (German)', dateFnsLocale: de },
  { code: 'hi', name: 'हिन्दी (Hindi)', dateFnsLocale: hi },
  { code: 'it', name: 'Italiano (Italian)', dateFnsLocale: it },
  { code: 'ja', name: '日本語 (Japanese)', dateFnsLocale: ja },
  { code: 'ko', name: '한국어 (Korean)', dateFnsLocale: ko },
  { code: 'ru', name: 'Русский (Russian)', dateFnsLocale: ru },
  { code: 'es', name: 'Español (Spanish)', dateFnsLocale: es },
  { code: 'uk', name: 'Українська (Ukranian)', dateFnsLocale: uk },
];

// export const supportedLanguages: SupportedLanguageType[] = [
//  { code: 'en', name: 'English', dateFnsLocale: enUS },
//  // { code: 'de', name: 'Deutsch', dateFnsLocale: de },
//  // { code: 'es', name: 'Español', dateFnsLocale: es },
//  // { code: 'et', name: 'Eesti', dateFnsLocale: et },
//  // { code: 'fr', name: 'Français', dateFnsLocale: fr },
//  // { code: 'id', name: 'Bahasa Indonesia', dateFnsLocale: id },
//  { code: 'ko', name: '한국어', dateFnsLocale: ko },
//  // { code: 'pt', name: 'Português', dateFnsLocale: pt },
//  // { code: 'ru', name: 'Русский', dateFnsLocale: ru },
//  // { code: 'tr', name: 'Türkçe', dateFnsLocale: tr },
//  // { code: 'uk', name: 'Українська', dateFnsLocale: uk },
//  // { code: 'vi', name: 'Tiếng Việt', dateFnsLocale: vi },
// ];
