import React from 'react';
import { Flex, PlacementWithLogical, Divider, Text, Popover, PopoverTrigger, PopoverContent, HStack } from '@chakra-ui/react';
import Link from 'next/link';
import { ChevronDown } from 'react-feather';
import { getMobilePopoverItems } from '~/lib/global/chain';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
import SocialLinks from '../../nav/components/SocialLinks';
import { useTranslations } from 'next-intl';
interface Props {
  placement?: PlacementWithLogical;
}
function PopoverLinks({
  placement
}: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const mobilePopoverItems = getMobilePopoverItems();
  const networkConfig = useNetworkConfig();
  const handleToggle = () => setIsOpen(!isOpen);
  const t = useTranslations();
  return <Popover isOpen={isOpen} onClose={() => setIsOpen(false)} placement={placement} trigger="click" data-sentry-element="Popover" data-sentry-component="PopoverLinks" data-sentry-source-file="PopoverLinks.tsx">
      <HStack data-sentry-element="HStack" data-sentry-source-file="PopoverLinks.tsx">
        <PopoverTrigger data-sentry-element="PopoverTrigger" data-sentry-source-file="PopoverLinks.tsx">
          <Flex borderRadius="12px" p="2px" onClick={handleToggle} _focus={{
          outline: 'none'
        }} tabIndex={0} data-sentry-element="Flex" data-sentry-source-file="PopoverLinks.tsx">
            <ChevronDown color="#ccc" style={{
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.2s'
          }} data-sentry-element="ChevronDown" data-sentry-source-file="PopoverLinks.tsx" />
          </Flex>
        </PopoverTrigger>
      </HStack>
      <PopoverContent padding="4" minWidth="250px" mr="2" borderRadius="18px" bg="outsetComponent" border="1px solid" borderColor="darkborder" boxShadow="popover.nav" data-sentry-element="PopoverContent" data-sentry-source-file="PopoverLinks.tsx">
        <Flex direction="column" w="100%" data-sentry-element="Flex" data-sentry-source-file="PopoverLinks.tsx">
          {mobilePopoverItems.map(item => <Link href={item.path} key={item.name} target={item.isExternal ? '_blank' : '_self'} rel={item.isExternal ? 'noopener noreferrer' : undefined}>
              <Flex align="center" py="6px">
                {item.icon && <item.icon style={{
              marginRight: '8px'
            }} />}
                <Text fontSize="18px">{item.name}</Text>
              </Flex>
            </Link>)}
          <Divider pt="2" data-sentry-element="Divider" data-sentry-source-file="PopoverLinks.tsx" />
          <Divider pb="2" data-sentry-element="Divider" data-sentry-source-file="PopoverLinks.tsx" />
        </Flex>
        <Flex direction="column" w="100%" align="flex-start" justify="flex-start" py="2" data-sentry-element="Flex" data-sentry-source-file="PopoverLinks.tsx">
          <Text fontSize="18px" color="text.100" py="2" data-sentry-element="Text" data-sentry-source-file="PopoverLinks.tsx">
            {t('Social')}
          </Text>
          <Flex w="100%" justify="space-between" data-sentry-element="Flex" data-sentry-source-file="PopoverLinks.tsx">
            <SocialLinks data-sentry-element="SocialLinks" data-sentry-source-file="PopoverLinks.tsx" />
          </Flex>
          <Divider pt="2" data-sentry-element="Divider" data-sentry-source-file="PopoverLinks.tsx" />
          <Divider pb="2" data-sentry-element="Divider" data-sentry-source-file="PopoverLinks.tsx" />
        </Flex>

        <Flex direction="column" w="100%" align="flex-start" h="100%" justify="flex-start" py="2" data-sentry-element="Flex" data-sentry-source-file="PopoverLinks.tsx">
          <Link href={networkConfig.socialLinks.documents} aria-label="Documentation link" data-sentry-element="Link" data-sentry-source-file="PopoverLinks.tsx">
            <Text fontSize="18px" color="text.100" data-sentry-element="Text" data-sentry-source-file="PopoverLinks.tsx">
              {t('Documentation')}
            </Text>
          </Link>

          <Divider py="2" data-sentry-element="Divider" data-sentry-source-file="PopoverLinks.tsx" />
        </Flex>
      </PopoverContent>
    </Popover>;
}
export default PopoverLinks;