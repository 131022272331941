'use client';

import { Box, Text, Flex, useDisclosure, useTheme } from '@chakra-ui/react';
import { MenuIcon, ChevronsRight } from 'lucide-react';
import { drawerMargin } from '.';
interface CollapseDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}
function CollapseDrawer({
  isOpen,
  onClose
}: CollapseDrawerProps) {
  const theme = useTheme();
  return <>
      <Flex mt="-1px" position="fixed" right={isOpen ? '310px' : '-48px'} h={`calc(100vh - ${drawerMargin})`} w="40px" zIndex="1" align="flex-start" justify="flex-start" cursor="pointer" bg="gray.925" borderTopLeftRadius="8px" borderLeftWidth="0.5px" borderTopWidth="0.5px" borderColor="transparent" transition="all 0.5s" _hover={{
      bg: 'gray.900',
      boxShadow: 'popover.xs',
      borderTopLeftRadius: '12px',
      borderColor: 'darkborder'
    }} onClick={onClose} data-sentry-element="Flex" data-sentry-source-file="CollapseDrawer.tsx">
        <Flex mt="4" data-sentry-element="Flex" data-sentry-source-file="CollapseDrawer.tsx">
          <ChevronsRight color={theme.colors.text[100]} size={32} data-sentry-element="ChevronsRight" data-sentry-source-file="CollapseDrawer.tsx" />
        </Flex>
      </Flex>
    </>;
}
export default CollapseDrawer;