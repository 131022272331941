'use client';

import React, { useEffect, useState } from 'react';
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, Box, Text, VStack, Flex, Button, Circle, useDisclosure, useTheme } from '@chakra-ui/react';
import { Lightbulb } from 'lucide-react';
import { getUpdatesForChain } from './updates';
import { NAVBAR_ITEMS_HEIGHT } from '~/app/components/wallet/NavbarConnect/NavbarConnectButton';
import { DexName } from '~/app/components/multichain/DexName';
import { useChainId } from 'wagmi';
import { useTranslations } from 'next-intl';
const ITEMS_PER_PAGE = 3;
const getLastViewedUpdate = () => {
  const stored = localStorage.getItem('lastViewedUpdate');
  return stored ? parseInt(stored) : 0;
};
export function WhatsNew() {
  const {
    isOpen,
    onOpen,
    onClose: baseOnClose
  } = useDisclosure();
  const [unreadCount, setUnreadCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const onClose = () => {
    setCurrentPage(1);
    baseOnClose();
  };
  const theme = useTheme();
  const chainId = useChainId();
  const chainUpdates = getUpdatesForChain(chainId);
  const totalPages = Math.ceil(chainUpdates.length / ITEMS_PER_PAGE);
  const currentUpdates = chainUpdates.slice(0, currentPage * ITEMS_PER_PAGE);
  const t = useTranslations();
  const tUpdates = useTranslations('Updates');
  useEffect(() => {
    const lastViewedId = getLastViewedUpdate();
    const newUpdatesCount = chainUpdates.filter(update => update.id > lastViewedId).length;
    setUnreadCount(newUpdatesCount);
  }, [chainId, chainUpdates]);
  const handleOpen = () => {
    onOpen();
    const latestUpdateId = Math.max(...chainUpdates.map(u => u.id));
    localStorage.setItem('lastViewedUpdate', latestUpdateId.toString());
    setUnreadCount(0);
  };
  const loadMore = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages));
  };
  return <Flex mx="1" position="relative" data-sentry-element="Flex" data-sentry-component="WhatsNew" data-sentry-source-file="index.tsx">
      <Popover isOpen={isOpen} onClose={onClose} trigger="hover" variant="settings" placement="bottom-start" data-sentry-element="Popover" data-sentry-source-file="index.tsx">
        <PopoverTrigger data-sentry-element="PopoverTrigger" data-sentry-source-file="index.tsx">
          <Button variant="colormodetoggle" height={NAVBAR_ITEMS_HEIGHT} onClick={handleOpen} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            <Lightbulb size={20} color={unreadCount > 0 ? theme.colors.highlight : theme.colors.gray[100]} data-sentry-element="Lightbulb" data-sentry-source-file="index.tsx" />
            {unreadCount > 0 && <Circle size="18px" bg="highlight" color="white" position="absolute" top="-8px" right="-8px" fontSize="12px" fontWeight="bold">
                {unreadCount}
              </Circle>}
          </Button>
        </PopoverTrigger>
        <PopoverContent maxW="400px" p="3" maxH="550px" overflowY="auto" data-sentry-element="PopoverContent" data-sentry-source-file="index.tsx">
          <PopoverBody data-sentry-element="PopoverBody" data-sentry-source-file="index.tsx">
            <Text fontSize="18px" fontWeight="500" mb={4} data-sentry-element="Text" data-sentry-source-file="index.tsx">
              {t('Whats New On')} <DexName data-sentry-element="DexName" data-sentry-source-file="index.tsx" />
            </Text>
            <VStack align="stretch" spacing={4} data-sentry-element="VStack" data-sentry-source-file="index.tsx">
              {currentUpdates.map(update => <Box key={update.id} p={3} borderRadius="12px" bg="transparent" boxShadow="insetComponentShadow">
                  <Text fontSize="14px" color="gray.300" mb={1}>
                    {update.date}
                  </Text>
                  <Text fontSize="16px">
                    {update.messageKey ? tUpdates(update.messageKey, update.messageParams) : update.message}
                  </Text>
                </Box>)}
            </VStack>
            {currentPage < totalPages && <Button onClick={loadMore} fontSize="14px" width="100%" mt={4}>
                {t('Load More')}
              </Button>}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>;
}