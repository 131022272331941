import { Flex, Popover, PopoverBody, PopoverContent, PopoverArrow, PopoverTrigger, useTheme as useChakraTheme, useColorMode, useDisclosure } from '@chakra-ui/react';
import { useTheme } from 'next-themes';
import { ChevronDown } from 'react-feather';
export function StatsPopover({
  children
}) {
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  const chakraTheme = useChakraTheme();
  const {
    theme
  } = useTheme();
  const {
    colorMode
  } = useColorMode();
  const isDarkMode = colorMode === 'dark' || theme === 'dark';
  return <Popover trigger="hover" placement="bottom" isOpen={isOpen} onOpen={onOpen} onClose={onClose} data-sentry-element="Popover" data-sentry-component="StatsPopover" data-sentry-source-file="StatsPopover.tsx">
      <PopoverTrigger data-sentry-element="PopoverTrigger" data-sentry-source-file="StatsPopover.tsx">
        <Flex _hover={{
        bg: isDarkMode ? 'gray.850' : 'gray.100'
      }} borderRadius={isDarkMode ? '12px' : '0'} p="0px" onClick={() => isOpen ? onClose() : onOpen()} cursor="pointer" data-sentry-element="Flex" data-sentry-source-file="StatsPopover.tsx">
          <ChevronDown color={isDarkMode ? chakraTheme.colors.text[100] : chakraTheme.colors.gray[800]} size={18} style={{
          transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.1s'
        }} data-sentry-element="ChevronDown" data-sentry-source-file="StatsPopover.tsx" />
        </Flex>
      </PopoverTrigger>
      <PopoverContent w="225px" borderRadius={isDarkMode ? '18px' : '0'} bg={isDarkMode ? 'gray.900' : 'white'} borderTop={isDarkMode ? 'none' : '1px solid'} borderColor={isDarkMode ? 'darkborder' : 'tableborder'} p="2" boxShadow={isDarkMode ? 'lifted' : 'none'} data-sentry-element="PopoverContent" data-sentry-source-file="StatsPopover.tsx">
        <PopoverArrow bg="text.100" data-sentry-element="PopoverArrow" data-sentry-source-file="StatsPopover.tsx" />

        <PopoverBody p="0" data-sentry-element="PopoverBody" data-sentry-source-file="StatsPopover.tsx">{children}</PopoverBody>
      </PopoverContent>
    </Popover>;
}