import { Text, Button, Flex, useTheme } from '@chakra-ui/react';
import { useGasPriceManager } from 'state/user/hooks';
import { GAS_PRICE, GAS_PRICE_GWEI } from '~/state/user/types';
import { FuelIcon, LucideFuel } from 'lucide-react';
import TooltipLabel from '../../tooltips/Label';
import { useTranslations } from 'next-intl';
const Gas = () => {
  const [gasPrice, setGasPrice] = useGasPriceManager();
  const theme = useTheme();
  const t = useTranslations();
  return <>
      <Flex direction="column" align="flex-start" justify="flex-start" my="2" data-sentry-element="Flex" data-sentry-source-file="Gas.tsx">
        <TooltipLabel icon={LucideFuel} iconSize={14} tooltipText={t('GasTooltip')} label={t('Gas')} color={theme.colors.gray[100]} data-sentry-element="TooltipLabel" data-sentry-source-file="Gas.tsx" />
        <Flex mt="1" justifyContent="space-between" width="100%" alignItems="center" data-sentry-element="Flex" data-sentry-source-file="Gas.tsx">
          <Button w="100%" height="35px" mx="1" onClick={() => {
          setGasPrice(GAS_PRICE_GWEI.default);
        }} variant={gasPrice === GAS_PRICE_GWEI.default ? 'ghostbutton' : 'toggleoff'} data-sentry-element="Button" data-sentry-source-file="Gas.tsx">
            Auto ({GAS_PRICE.default})
          </Button>
          <Button w="100%" mx="1" height="35px" onClick={() => {
          setGasPrice(GAS_PRICE_GWEI.fast);
        }} variant={gasPrice === GAS_PRICE_GWEI.fast ? 'ghostbutton' : 'toggleoff'} data-sentry-element="Button" data-sentry-source-file="Gas.tsx">
            Fast ({GAS_PRICE.fast})
          </Button>
          <Button w="100%" mx="1" height="35px" onClick={() => {
          setGasPrice(GAS_PRICE_GWEI.instant);
        }} variant={gasPrice === GAS_PRICE_GWEI.instant ? 'ghostbutton' : 'toggleoff'} data-sentry-element="Button" data-sentry-source-file="Gas.tsx">
            Instant ({GAS_PRICE.instant})
          </Button>
        </Flex>
      </Flex>
    </>;
};
export default Gas;