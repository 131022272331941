import { useLocale } from 'next-intl';

interface FormatOptions {
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  style?: 'decimal' | 'percent';
  digits?: number;
  notation?: 'standard' | 'scientific' | 'engineering' | 'compact';
}

export function useInternationalNumber() {
  const locale = useLocale() || 'en-US';

  const formatCompactNumber = (num: number) => {
    const absNum = Math.abs(num);
    const sign = num < 0 ? '-' : '';

    if (absNum >= 1e9) {
      return sign + (absNum / 1e9).toFixed(1) + 'B';
    }
    if (absNum >= 1e6) {
      return sign + (absNum / 1e6).toFixed(1) + 'M';
    }
    if (absNum >= 1e3) {
      return sign + (absNum / 1e3).toFixed(1) + 'K';
    }
    return new Intl.NumberFormat(locale, {
      maximumFractionDigits: 1,
      minimumFractionDigits: 1,
    }).format(num);
  };

  return (value: number, options: FormatOptions = {}) => {
    const isPercentage = options.style === 'percent';
    const isCompact = options.notation === 'compact';

    if (isCompact) {
      return formatCompactNumber(value || 0);
    }

    const fractionDigits =
      options.digits !== undefined
        ? {
            maximumFractionDigits: options.digits,
            minimumFractionDigits: options.digits,
          }
        : {
            maximumFractionDigits: options.maximumFractionDigits ?? (isPercentage ? 1 : 2),
            minimumFractionDigits: options.minimumFractionDigits ?? (isPercentage ? 1 : 2),
          };

    return new Intl.NumberFormat(locale, {
      ...fractionDigits,
      style: options.style ?? 'decimal',
      useGrouping: true,
    }).format(value || 0);
  };
}

export function useLocalTimeWithTimeZone(dateString, locale) {
  return new Date(dateString).toLocaleString(locale, {
    day: '2-digit',
    month: 'short',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
}
