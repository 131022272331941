import { NetworkConfig } from './network-config-type';

export const ModeNetworkConfig: NetworkConfig = {
  appName: 'SwapMode',
  chainId: 34443,
  networkName: 'Mode Network',
  networkShortName: 'Mode',
  etherscanName: 'Mode Scan',
  etherscanUrl: 'https://modescan.io',
  testnet: false,
  isV2Supported: true,
  ens: {
    hasWebNameSupport: true,
    nameServiceId: 'mode',
  },
  dexIcon:
    'https://raw.githubusercontent.com/baseswapfi/default-token-list/main/images/SMDLOGO.png',
  dexIconInRepo: '/logo/SMDLOGO.png',
  iconUrl:
    'https://raw.githubusercontent.com/mode-network/brandkit/refs/heads/main/Assets/Logo/Token.svg',
  eth: {
    name: 'ETH',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    symbol: 'ETH',
    decimals: 18,
    iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  },
  wethAddress: '0x4200000000000000000000000000000000000006',
  wethAddressFormatted: '0x4200000000000000000000000000000000000006',
  tokens: {
    wbtc: '0xcdd475325d6f564d27247d1dddbb0dac6fa0a5cf',
    mode: '0xDfc7C877a950e49D2610114102175A06C2e3167a',
  },
  coingecko: {
    nativeAssetId: 'ether',
    platformId: 'ethereum',
  },
  rpcUrl: 'https://mainnet.mode.network/',
  multicall: '0xe52a36Bb76e8f40e1117db5Ff14Bd1f7b058B720',
  contracts: {
    v2Router: '0xc1e624c810d297fd70ef53b0e08f44fabe468591',
    v2Zap: '0xbf79915e80de0a361a4f35175ba9bf2e91b10424',
    uniMulticall: '0x7bb14ed986dae0c8423350a7f1c59a31b3c84509',
    v3: {
      factory: '0x6E36FC34eA123044F278d3a9F3819027B21c9c32',
      nonFungibleManager: '0xcc3726bCc27f232bC1CaAB40853AEa91ae43C216',
      tickLens: '0x62e879c8979694DbC3A4EF1dd324b08Ee3Ac3688',
    },
    tokenLocker: '0x7C0b97cD0FDc6B924129344502dd9De100A2f934',
    yieldBooster: '0x0fE9E7B39dbdfe32c9F37FAcCec6b33d290CbF50',
  },
  protocolToken: {
    address: '0xfda619b6d20975be80a10332cd39b9a4b0faa8bb',
    symbol: 'SMD',
  },
  xProtocolToken: {
    address: '0xFb68BBfaEF679C1E653b5cE271a0A383c0df6B45',
    symbol: 'xSMD',
  },
  lockerPayingToken: {
    address: '0xfda619b6d20975be80a10332cd39b9a4b0faa8bb',
    symbol: 'SMD',
  },
  mainPoolId: '',
  masterChefContractAddress: '',
  defaultTokenIn: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  defaultTokenOut: '0xfda619b6d20975be80a10332cd39b9a4b0faa8bb',
  farmTypeName: '',
  additionalLinks: [],
  priceImpact: {
    invest: {
      noticeable: 0.005,
      high: 0.01,
    },
    trade: {
      noticeable: 0.01,
      high: 0.05,
    },
    withdraw: {
      noticeable: 0.005,
      high: 0.01,
    },
  },
  odos: {
    isOdosChain: true,
    referralCode: 900225284,
    routerAddress: '0x7E15EB462cdc67Cf92Af1f7102465a8F8c784874',
  },
  merkl: {
    isSupported: true,
    distributorAddress: '0x3Ef3D8bA38EBe18DB133cEc108f4D14CE00Dd9Ae',
    additionalRewardToken1: '',
    additionalRewardToken1Symbol: '',
  },
  socialLinks: {
    reddit: 'https://www.reddit.com/r/SwapMode/',
    twitter: 'https://twitter.com/SwapModeFi',
    telegram: 'https://t.me/swapmode',
    // youtube: "",
    medium: 'https://medium.com/@SwapMode',
    discord: 'https://discord.com/invite/JgCfPDe6YJ',
    // linktree: "https://linktr.ee/swapmodefi",
    // warpcast: "https://warpcast.com/swapmode",
    documents: 'https://swapmode.gitbook.io/swapmode/',
    // coingecko: 'https://www.coingecko.com/en/coins/swapmode',
    // dexscreener: 'https://dexscreener.com/mode/0x7f3315d773350883ebb4f643ab5ec0d32d162c8a'
  },
  bridgeExternal1: {
    name: 'Across Protocol Bridge',
    link: 'https://app.across.to/bridge?',
  },
  locker: {
    lockingToken: 'SMD',
  },
  a51: {
    dexName: 'swapmode-uniswap-v3-cloned',
    serverEndpoint: 'https://api.a51.finance',
    repoUrl: 'https://automated.swapmode.fi',
    isSupported: true,
  },
  across: {
    integratorId: '0x0045',
  },
};

// ***Official SwapMode Information***
// SMD contract:  https://explorer.mode.network/token/0xFDa619b6d20975be80A10332cD39b9a4b0FAa8BB
// xSMD contract:  https://explorer.mode.network/token/0xFb68BBfaEF679C1E653b5cE271a0A383c0df6B45

// Dexscreener:  https://dexscreener.com/mode/0x7f3315d773350883ebb4f643ab5ec0d32d162c8a

// Coingecko:  https://www.coingecko.com/en/coins/swapmode
