'use client';

import { Flex, Grid, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import Dashboard from '~/app/components/icons/Sticker/Dashboard';
import NinjaIcon from '~/app/components/icons/Sticker/NinjaIcon';
import StakingIcon from '~/app/components/icons/Sticker/StakingIcon';
import RabbitIcon from '~/app/components/icons/Sticker/RabbitIcon';
import SimpleCard from './SimpleCard';
import BullIcon from '~/app/components/icons/Sticker/BullIcon';
import InterfaceIcon from '~/app/components/icons/Sticker/InterfaceIcon';
import SectionTitleText from '~/app/home/components/SectionTitleText';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
import { useTranslations } from 'next-intl';
export default function CardGrid() {
  const [flippedIndex, setFlippedIndex] = useState(null);
  const t = useTranslations();
  const handleCardClick = index => {
    setFlippedIndex(index === flippedIndex ? null : index);
  };
  const networkConfig = useNetworkConfig();
  const tSimple = useTranslations('Simple');

  // the above ensures that if you click a card and one is already flipped, it will revert.
  // disable if we want users to have multiple cards flipped at once.

  return <Flex direction="column" justify="center" align="center" w="100%" data-sentry-element="Flex" data-sentry-component="CardGrid" data-sentry-source-file="index.tsx">
      <Flex justify="flex-end" w="100%" data-aos-id-simple data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <SectionTitleText data-aos-delay="200" data-aos-mirror="true" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-offset="100" aosAnchor="[data-aos-id-simple]" data-sentry-element="SectionTitleText" data-sentry-source-file="index.tsx">
          {t('KeepsItSimple', {
          symbol: networkConfig.appName
        })}
        </SectionTitleText>
      </Flex>
      <Flex w="100%" justify={{
      base: 'center',
      lg: 'flex-start'
    }} data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <Grid templateColumns={{
        base: '1fr',
        md: 'repeat(2, 1fr)',
        lg: 'repeat(3, 1fr)'
      }} gap={{
        base: 2,
        lg: 4
      }} maxW={{
        md: '2xl',
        lg: '50%'
      }} data-aos-id-blocks data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          {[{
          type: 'light',
          delay: '100',
          frontIcon: <InterfaceIcon />,
          frontText: t('User-centric interface'),
          backText: tSimple('ForEveryone', {
            symbol: networkConfig.appName
          })
        }, {
          type: 'dark',
          delay: '200',
          frontIcon: <RabbitIcon />,
          frontText: t('Instant Swaps'),
          backText: tSimple('InstantSwapsText', {
            symbol: networkConfig.appName
          })
        }, {
          type: 'light',
          delay: '200',
          frontIcon: <NinjaIcon />,
          frontText: t('V3 Liquidity Made Easy'),
          backText: tSimple('V3')
        }, {
          type: 'dark',
          delay: '200',
          frontIcon: <StakingIcon />,
          frontText: t('Staking'),
          backText: tSimple('Staking', {
            symbol: networkConfig.appName
          })
        }, {
          type: 'light',
          delay: '200',
          frontIcon: <BullIcon />,
          frontText: t('Automated Liquidity'),
          backText: tSimple('A51', {
            symbol: 'A51 Finance'
          })
        }, {
          type: 'dark',
          delay: '200',
          frontIcon: <Dashboard />,
          frontText: t('Dashboard'),
          backText: tSimple('Dashboard')
        }].map((card, index) => <SimpleCard key={index} type={card.type} delay={card.delay} frontContent={<Flex p="2" h="190px" w="350px" direction="column" justify="center" align="center">
                  {card.frontIcon}
                  <Text fontSize="20px" fontWeight="500" mb={2} userSelect="none">
                    {card.frontText}
                  </Text>
                </Flex>} backContent={<Flex p="2" px="4" h="190px" w="350px" direction="column" justify="center" align="center">
                  <Text fontSize="14px" userSelect="none" textAlign="left">
                    {card.backText}
                  </Text>
                </Flex>} isFlipped={flippedIndex === index} onClick={() => handleCardClick(index)} />)}
        </Grid>
      </Flex>
    </Flex>;
}