export const chainFeaturedPools: { [chainId: number]: string[] } = {
  8453: [
    '8453-0x95652709f0ce29c1bf16576ea1d3de2a007727f4',
    '8453-0x05218c218ad794d171e0bf45e9d9f1f8ae547059',
    '8453-0xe5ff3464fab64aba7c828ebd9730cab4e2e2b910',
  ],
  34443: [
    '34443-0x564227fb8a192752446839950f8119d94d941774',
    '34443-0xa1c6800788482ba0eeb85f47322bb789986ee2f3',
    '34443-0x20cf165da8c40f173b5c75d9e085b3c76a9b75a5',
  ],
  10: [
    '10-0x4909b81d7338d028d99211830cf802dbd74cac1a',
    '10-0x186c67654d0677dc3d64b01a2f72f196cd9bece9',
    '10-0x58e9f6309a483de60a8d74b5cb870dc13e195b46',
  ],
  42161: [
    '42161-0xbde832b5e1e7a98e73703a558d751f2846258eae',
    '42161-0x3dbf77bb506eabd794c703a63fb79ee6a9e85918',
    '42161-0x9afc3c794d3e5E107e1F85eFf2b9779fb556E219',
  ],
};

// make sure the lowercase address is used; can also add tolowercase if its easier

// export const chainFeaturedPools: { [chainId: number]: string[] } = {
//   8453: [
//      '8453-0xa53a448064cBF8ae5C1018852D66564c0B13fAe7',
//      '8453-0x94bb99AA0957dfBEBbb3a5Dc1686DFE138387265',
//      '8453-0xa53a448064cBF8ae5C1018852D66564c0B13fAe7',
//      '8453-0x8B38CA1E4F494685E0CCDE5bAcd9EE3dF7d4AE0C',
//      '8453-0xe8598ada6b7A1a41f78C54a51cF15Bd2eb79A8e0',
//      '8453-0x9c3F1bF5D7069e241aE4BD80b8120AF358baea4C',

//    ],
//    34443: [
//      '34443-0x564227fb8a192752446839950f8119d94d941774',
//      '34443-0xa1c6800788482ba0eeb85f47322bb789986ee2f3',
//      '34443-0x20cf165da8c40f173b5c75d9e085b3c76a9b75a5',
//    ],
//    10: [
//      '10-0x4909b81d7338d028d99211830cf802dbd74cac1a',
//      '10-0x186c67654d0677dc3d64b01a2f72f196cd9bece9',
//      '10-0x58e9f6309a483de60a8d74b5cb870dc13e195b46',
//    ],
//  };

//  // make sure the lowercase address is used; can also add tolowercase if its easier
