import { mode } from '@chakra-ui/theme-tools';

export const Switch = {
  variants: {
    pawgisland: (props) => ({
      track: {
        bg: mode('#404040', '#404040')(props),
        border: '1px solid',
        borderColor: 'darkborder',
        _checked: {
          bg: mode('highlight', 'highlight')(props),
        },
      },
      thumb: {
        bg: mode('#fff', '#fff')(props),
        _checked: {
          bg: mode('white', 'white')(props),
        },
      },
    }),
  },
};
