'use client';

import { useMemo } from 'react';
import { Flex, Grid } from '@chakra-ui/react';
import SectionTitleText from '~/app/home/components/SectionTitleText';
import { useV3PoolList } from '~/app/pools/lib/v3/useV3PoolList';
import { getFeaturedPools } from '~/lib/global/chain';
import FeaturedPoolsCard from '../FeaturedPoolsCard';
import FeaturedPoolContent from '../FeaturedPoolsCard/Content';
import { useTranslations } from 'next-intl';
export default function FeaturedPoolsGrid() {
  const {
    poolsWithLiquidity
  } = useV3PoolList();
  const featuredPoolsArray = getFeaturedPools();
  const poolsMap = useMemo(() => {
    const map = new Map();
    poolsWithLiquidity.forEach(pool => {
      map.set(pool.id.toLowerCase(), pool);
    });
    return map;
  }, [poolsWithLiquidity]);
  const featuredPools = useMemo(() => {
    if (!featuredPoolsArray) return [];
    return featuredPoolsArray.map(id => poolsMap.get(id.toLowerCase())).filter(Boolean);
  }, [featuredPoolsArray, poolsMap]);
  const t = useTranslations();
  return <Flex direction="column" minW="100%" w="100%" data-sentry-element="Flex" data-sentry-component="FeaturedPoolsGrid" data-sentry-source-file="index.tsx">
      <Flex justify="flex-start" w="100%" data-aos-id-featuredpoolstitle data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <SectionTitleText aosAnchor="[data-aos-id-featuredpoolstitle]" data-sentry-element="SectionTitleText" data-sentry-source-file="index.tsx">
          {t('Featured Pools')}
        </SectionTitleText>
      </Flex>

      <Flex w="100%" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <Grid templateColumns={{
        base: '1fr',
        lg: 'repeat(2, 1fr)',
        xxl: 'repeat(3, 1fr)'
      }} gap={{
        base: 2,
        lg: 16
      }} maxW={{
        base: '2xl',
        lg: 'none'
      }} mx="auto" alignItems="center" justifyItems="center" data-aos-id-featuredpools data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          {featuredPools.map((pool, index) => <FeaturedPoolsCard key={pool.id} type={index % 2 === 0 ? 'light' : 'dark'} delay={`${index * 200}`}>
              <FeaturedPoolContent pool={pool} />
            </FeaturedPoolsCard>)}
        </Grid>
      </Flex>
    </Flex>;
}