import { useLocale } from 'next-intl';

interface FormatOptions {
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  style?: 'decimal' | 'currency';
  currency?: string;
  digits?: number;
}

export function useInternationalCurrency() {
  const locale = useLocale();

  const formatCompactNumber = (num: number) => {
    const absNum = Math.abs(num);
    const localeNumber = new Intl.NumberFormat(locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });

    if (absNum >= 1e9) return `$${localeNumber.format(absNum / 1e9)}B`;
    if (absNum >= 1e6) return `$${localeNumber.format(absNum / 1e6)}M`;
    if (absNum >= 1e3) return `$${localeNumber.format(absNum / 1e3)}K`;
    return `$${localeNumber.format(num)}`;
  };

  return (
    value: number,
    currencyCode = 'USD',
    options: Omit<FormatOptions, 'style' | 'currency'> & { compact?: boolean } = {},
  ) => {
    if (currencyCode === 'USD') {
      return options.compact ? 
        formatCompactNumber(value || 0) :
        new Intl.NumberFormat(locale, {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          ...options
        }).format(value || 0);
    }

    return new Intl.NumberFormat(locale, {
      ...options,
      style: 'currency',
      currency: currencyCode,
    }).format(value || 0);
  };
}
