import { mode } from '@chakra-ui/theme-tools';

export const Popover = {
  variants: {
    badge: (props) => ({
      content: {
        bg: mode('porcelain', 'black')(props),
        color: 'text.100',
        borderRadius: '18px',
        padding: '2',
        borderWidth: mode('2px', '1px')(props),
        borderColor: mode('highlight', 'highlight')(props),
        boxShadow: 'insetComponent1',
      },
    }),
    elevated: (props) => ({
      content: {
        boxShadow: mode('md', 'dark-lg')(props),
        bg: mode('white', 'gray.800')(props),
      },
    }),
    outline: (props) => ({
      content: {
        borderWidth: '1px',
        borderColor: mode('gray.200', 'gray.600')(props),
        width: 'fit-content',
      },
    }),

    settings: (props) => ({
      content: {
        width: 'fit-content !important',
        borderRadius: '16px !important',
        background: 'alphaGradient.100 !important',
        boxShadow: 'popover.dropShadow',
        borderWidth: '1px',
        borderColor: mode(
          'rgba(0, 0, 0, 0.05) !important',
          'rgba(255, 255, 255, 0.1) !important',
        )(props),
        padding: '8px !important',
      },
      header: {
        color: 'text.100 !important',
        marginBottom: '12px !important',
      },
      body: {
        background: 'alphaGradient.200 !important',
        boxShadow: 'popover.xs',
        borderRadius: '12px !important',
        padding: '12px !important',
      },
      arrow: {
        background: '#F0EEE6 !important',
        boxShadow: 'popover.arrow',
      },
      closeButton: {},
    }),
  },
  defaultProps: {
    variant: 'outline',
  },
};
