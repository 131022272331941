'use client';

import { Drawer, DrawerContent, DrawerBody, Flex, IconButton, Box, useDisclosure, useTheme } from '@chakra-ui/react';
import { MenuIcon } from 'lucide-react';
import { NAVBAR_ITEMS_HEIGHT } from '~/app/components/wallet/NavbarConnect/NavbarConnectButton';
import Image from 'next/image';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
import DisplaySettings from './DisplaySettings';
import AppSettings from './AppSettings';
import CollapseDrawer from './CollapseDrawer';
export const drawerMargin = '32px';
function NavbarDrawer() {
  const networkConfig = useNetworkConfig();
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  const theme = useTheme();
  return <>
      <IconButton aria-label="Menu" variant="colormodetoggle" h={NAVBAR_ITEMS_HEIGHT} icon={<MenuIcon color={theme.colors.text[100]} />} onClick={onOpen} display={{
      base: 'flex',
      md: 'flex'
    }} data-sentry-element="IconButton" data-sentry-source-file="index.tsx" />

      {/* <CollapseDrawer isOpen={isOpen} onClose={onClose} /> */}
      <Drawer placement="right" onClose={onClose} isOpen={isOpen} data-sentry-element="Drawer" data-sentry-source-file="index.tsx">
        <DrawerContent mt={drawerMargin} bg="gray.900" borderTopWidth="1px" borderLeftWidth="1px" borderTopLeftRadius="12px" borderColor="darkborder" zIndex="1" data-sentry-element="DrawerContent" data-sentry-source-file="index.tsx">
          {' '}
          <CollapseDrawer isOpen={isOpen} onClose={onClose} data-sentry-element="CollapseDrawer" data-sentry-source-file="index.tsx" />
          <DrawerBody w={{
          base: '300px',
          lg: 'auto'
        }} zIndex="2" p="0" data-sentry-element="DrawerBody" data-sentry-source-file="index.tsx">
            <Box data-sentry-element="Box" data-sentry-source-file="index.tsx">
              <DisplaySettings data-sentry-element="DisplaySettings" data-sentry-source-file="index.tsx" />
              <AppSettings data-sentry-element="AppSettings" data-sentry-source-file="index.tsx" />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>;
}
export default NavbarDrawer;