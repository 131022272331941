'use client';

import numeral from 'numeral';
import { useGetProtocolDataQuery } from '~/apollo/generated/graphql-codegen-generated';
import { SubNavBarStat } from './ProtocolStats/SubNavBarStat';
import { useChainId } from 'wagmi';
import { ProtocolVolume } from './ProtocolStats/ProtocolVolume';
import { ProtocolTVL } from './ProtocolStats/ProtocolTVL';
import { StatsPopover } from './ProtocolStats/StatsPopover';
import { SubNavTicker } from './Ticker';
import { useInternationalCurrency } from '~/i18n/hooks/useInternationalCurrency';
import { useInternationalNumber } from '~/i18n/hooks/useInternationalNumber';
export function SubNavContent() {
  const chainId = useChainId();
  const {
    data,
    loading
  } = useGetProtocolDataQuery({
    variables: {
      chainId
    },
    fetchPolicy: 'cache-first'
  });
  const protocolData = data?.protocolData;
  const formatCurrency = useInternationalCurrency();
  const tvl = Number((protocolData?.totalLiquidity ?? '0').replace(/[$,]/g, ''));
  const volume = Number((protocolData?.swapVolume24h ?? '0').replace(/[$,]/g, ''));
  return <>
      <SubNavBarStat loading={loading && !protocolData} value={formatCurrency(tvl, 'USD', {
      compact: true
    })} label="TVL" extra={<StatsPopover>
            <ProtocolTVL />
          </StatsPopover>} data-sentry-element="SubNavBarStat" data-sentry-source-file="SubNavContent.tsx" />

      <SubNavBarStat loading={loading && !protocolData} value={formatCurrency(volume, 'USD', {
      compact: true
    })} label="Vol (24h)" extra={<StatsPopover>
            <ProtocolVolume />
          </StatsPopover>} data-sentry-element="SubNavBarStat" data-sentry-source-file="SubNavContent.tsx" />
      <SubNavTicker data-sentry-element="SubNavTicker" data-sentry-source-file="SubNavContent.tsx" />
    </>;
}