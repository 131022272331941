'use client';

import { Flex, Grid, Text } from '@chakra-ui/react';
import numeral from 'numeral';
import { useChainId } from 'wagmi';
import { useGetProtocolDataQuery } from '~/apollo/generated/graphql-codegen-generated';
import { CHAIN_MAIN, CHAIN_MAIN_CHAIN_NAME, CHAIN_MAIN_NAME } from '~/constants';
import SectionShell from '../../components/SectionShell';
import SectionTitleText from '../../components/SectionTitleText';
import OptimismFoundation from './OptimismFoundation';
import StatCard from './StatCard';
import { useTranslations } from 'next-intl';
import { useInternationalCurrency } from '~/i18n/hooks/useInternationalCurrency';
import { useInternationalNumber } from '~/i18n/hooks/useInternationalNumber';
export default function Section5Stats() {
  const chainId = useChainId() || CHAIN_MAIN;
  const t = useTranslations();
  const formatCurrency = useInternationalCurrency();
  const formatNumber = useInternationalNumber();
  const {
    data
  } = useGetProtocolDataQuery({
    variables: {
      chainId
    },
    fetchPolicy: 'cache-first'
  });
  const protocolData = data?.protocolData;
  const allTimeVolume = formatCurrency((protocolData?.allTimeV3?.totalVolumeUSD || 0) + (protocolData?.allTimeV2?.totalVolumeUSD || 0));
  const allTimeFees = formatCurrency((protocolData?.allTimeV3?.totalFeesUSD || 0) + (protocolData?.allTimeV2?.totalFeesUSD || 0));
  const todaysVolume = formatCurrency(Number(protocolData?.swapVolume24h?.replace(/[$,]/g, '') || '0'));
  return <SectionShell data-sentry-element="SectionShell" data-sentry-component="Section5Stats" data-sentry-source-file="index.tsx">
      <Grid templateColumns={{
      base: '1fr',
      lg: '1fr 1fr'
    }} templateRows="1fr" gap={6} p={4} w="100%" h="100%" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Flex direction="column" flex="1" justify="space-between" align="flex-start" w={{
        base: '100%',
        lg: '100%'
      }} p={4} h="100%" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
          <Flex h="100%" align="flex-start" direction="column" justify="flex-start" data-aos-id-stats data-sentry-element="Flex" data-sentry-source-file="index.tsx">
            <SectionTitleText aosAnchor="[data-aos-id-stats]" data-sentry-element="SectionTitleText" data-sentry-source-file="index.tsx">{t('WeveBeenHere')}</SectionTitleText>
            <Text fontSize="24px" mt="-1rem" ml="8px" color="highlight" data-sentry-element="Text" data-sentry-source-file="index.tsx">
              {t('From the start')}
            </Text>
          </Flex>
          <Flex h="100%" w={{
          base: '100%',
          lg: '75%'
        }} align="flex-end" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
            <Text fontSize={{
            base: '16px',
            lg: '18px'
          }} data-sentry-element="Text" data-sentry-source-file="index.tsx">
              {t('BeenAroundMessage', {
              symbol1: CHAIN_MAIN_NAME,
              symbol2: CHAIN_MAIN_CHAIN_NAME
            })}{' '}
            </Text>
          </Flex>
        </Flex>
        <Grid templateColumns={{
        base: '1fr',
        md: 'repeat(2, 1fr)'
      }} gap={4} flex={1} w={{
        base: '100%',
        lg: '100%'
      }} data-aos-id-statcard data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <StatCard topText={t('All time volume')} bottomText={allTimeVolume} bottomTextColor="highlight" data-sentry-element="StatCard" data-sentry-source-file="index.tsx" />

          {chainId !== 34443 && <StatCard topText={t('ChainUsers', {
          symbol: CHAIN_MAIN_NAME
        })} bottomText="120k" bottomTextColor="highlight" />}
          <StatCard topText={t('All time fees')} bottomText={allTimeFees} bottomTextColor="highlight" data-sentry-element="StatCard" data-sentry-source-file="index.tsx" />
          <StatCard topText={t('Todays Volume')} bottomText={todaysVolume} bottomTextColor="highlight" data-sentry-element="StatCard" data-sentry-source-file="index.tsx" />
        </Grid>
      </Grid>
      <Flex w="100%" mt="1rem" justify="flex-end" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <OptimismFoundation data-sentry-element="OptimismFoundation" data-sentry-source-file="index.tsx" />
      </Flex>
    </SectionShell>;
}