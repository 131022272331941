import { Text } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
interface SectionTitleTextProps {
  children: ReactNode;
  aosOffset?: string;
  aosAnchor?: string;
}
export default function SectionTitleText({
  children,
  aosOffset = '300',
  aosAnchor
}: SectionTitleTextProps) {
  return <Text userSelect="none" fontSize={{
    base: '32px',
    lg: '52px'
  }} mb={4} data-aos-delay="100" data-aos-mirror="true" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-offset={aosOffset} data-aos-anchor={aosAnchor} data-sentry-element="Text" data-sentry-component="SectionTitleText" data-sentry-source-file="SectionTitleText.tsx">
      {children}
    </Text>;
  // for anchor placement, top bottom refers to the top of the animated component entering the viewport, from the bottom.
  // so when the top of the component enters the bottom of the viewport.
}