'use client';

import { Text, Flex, Divider } from '@chakra-ui/react';
import { useGetProtocolDataQuery } from '~/apollo/generated/graphql-codegen-generated';
import { useChainId } from 'wagmi';
import { useTranslations } from 'next-intl';
import { useInternationalCurrency } from '~/i18n/hooks/useInternationalCurrency';
export function ProtocolTVL() {
  const chainId = useChainId();
  const t = useTranslations();
  const formatCurrency = useInternationalCurrency();
  const {
    data
  } = useGetProtocolDataQuery({
    variables: {
      chainId
    },
    fetchPolicy: 'cache-first'
  });
  const protocolData = data?.protocolData;
  const formatValue = (value: string) => {
    const cleanValue = value?.replace(/[$,]/g, '') || '0';
    return formatCurrency(parseFloat(cleanValue), 'USD', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    });
  };
  return <>
      <Flex justifyContent="space-between" mt="2" data-sentry-element="Flex" data-sentry-source-file="ProtocolTVL.tsx">
        <Text color="gray.300" fontSize={{
        base: 'sm',
        lg: '14px'
      }} data-sentry-element="Text" data-sentry-source-file="ProtocolTVL.tsx">
          {t('V2')}
        </Text>
        <Text fontSize={{
        base: 'sm',
        lg: '14px'
      }} data-sentry-element="Text" data-sentry-source-file="ProtocolTVL.tsx">
          {' '}
          {formatValue(protocolData?.totalLiquidityV2)}
        </Text>
      </Flex>

      <Divider pt="2" data-sentry-element="Divider" data-sentry-source-file="ProtocolTVL.tsx" />

      <Flex justifyContent="space-between" data-sentry-element="Flex" data-sentry-source-file="ProtocolTVL.tsx">
        <Text color="gray.300" fontSize={{
        base: 'sm',
        lg: '14px'
      }} data-sentry-element="Text" data-sentry-source-file="ProtocolTVL.tsx">
          {t('V3')}
        </Text>
        <Text fontSize={{
        base: 'sm',
        lg: '14px'
      }} data-sentry-element="Text" data-sentry-source-file="ProtocolTVL.tsx">
          {' '}
          {formatValue(protocolData?.totalLiquidityV3)}
        </Text>
      </Flex>

      <Divider pt="2" data-sentry-element="Divider" data-sentry-source-file="ProtocolTVL.tsx" />

      <Flex justifyContent="space-between" data-sentry-element="Flex" data-sentry-source-file="ProtocolTVL.tsx">
        <Text color="gray.300" fontSize={{
        base: 'sm',
        lg: '14px'
      }} data-sentry-element="Text" data-sentry-source-file="ProtocolTVL.tsx">
          {t('Other')}
        </Text>
        <Text fontSize={{
        base: 'sm',
        lg: '14px'
      }} data-sentry-element="Text" data-sentry-source-file="ProtocolTVL.tsx"> {formatValue(protocolData?.stakersTVL)}</Text>
      </Flex>
    </>;
}