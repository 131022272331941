'use client';

import { Box, Text, Flex, Button, useTheme } from '@chakra-ui/react';
import { ChevronRight, Check, Globe, X } from 'lucide-react';
import { supportedLanguages } from '~/i18n/supportedLanguages';
import { useLocale, useTranslations } from 'next-intl';
import { useState } from 'react';
function MobileLanguage() {
  const currentLocale = useLocale();
  const [showLanguages, setShowLanguages] = useState(false);
  const theme = useTheme();
  const currentLanguage = supportedLanguages.find(lang => lang.code === currentLocale)?.name || 'English';
  const handleLanguageChange = (code: string) => {
    document.cookie = `NEXT_LOCALE=${code}; path=/`;
    window.location.reload();
  };
  const t = useTranslations();
  return <Flex w="100%" direction="column" data-sentry-element="Flex" data-sentry-component="MobileLanguage" data-sentry-source-file="MobileLanguage.tsx">
      <Flex w="100%" mb="2" align="center" justify="flex-start" data-sentry-element="Flex" data-sentry-source-file="MobileLanguage.tsx">
        <Text mx="2" data-sentry-element="Text" data-sentry-source-file="MobileLanguage.tsx">{t('Language')} </Text>
        <Globe size="16px" color={theme.colors.text[100]} data-sentry-element="Globe" data-sentry-source-file="MobileLanguage.tsx" />
      </Flex>

      {!showLanguages ? <Button w="full" variant="ghost" onClick={() => setShowLanguages(true)} rightIcon={<ChevronRight color={theme.colors.text[100]} />} justifyContent="space-between">
          <Text color="text.100" fontWeight="500">
            {currentLanguage}
          </Text>
        </Button> : <Box>
          <Flex justify="flex-end" mb={2}>
            <X size={16} style={{
          cursor: 'pointer'
        }} onClick={() => setShowLanguages(false)} />
          </Flex>
          <Box>
            {supportedLanguages.map(lang => <Button key={lang.code} onClick={() => handleLanguageChange(lang.code)} w="full" h="35px" variant={currentLocale === lang.code ? 'ghostbutton' : 'toggleoff'} justifyContent="space-between" mb={1} rightIcon={currentLocale === lang.code ? <Check size={16} color={theme.colors.highlight} /> : undefined}>
                <Text color="text.100" fontWeight="500">
                  {lang.name}
                </Text>
              </Button>)}
          </Box>
        </Box>}
    </Flex>;
}
export default MobileLanguage;