import { HStack, Skeleton, Text } from '@chakra-ui/react';
import TokenAvatar from '~/app/components/token/TokenAvatar';
import numeral from 'numeral';
export function TickerItem({
  loading,
  token
}) {
  // const formatNumber = useInternationalNumber();

  // const displayNumber = (price) => {
  //   if (price > 1000) {
  //     return formatNumber(price, { digits: 0 });
  //   }
  //   return formatNumber(price, { digits: 3 });
  // };
  return loading ? <Skeleton height="16px" width="54px" data-sentry-element="Skeleton" data-sentry-component="TickerItem" data-sentry-source-file="TickerItem.tsx" /> : <HStack spacing="3px" data-sentry-element="HStack" data-sentry-component="TickerItem" data-sentry-source-file="TickerItem.tsx">
      <TokenAvatar alt={token.name} address={token.address} size={14} data-sentry-element="TokenAvatar" data-sentry-source-file="TickerItem.tsx" />
      <Text fontSize="14px" color="text.100" data-sentry-element="Text" data-sentry-source-file="TickerItem.tsx">
        {numeral(token.price).format(token.format)}
      </Text>
    </HStack>;
}