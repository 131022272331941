'use client';

import { Box, Text, Button, useColorMode, Flex, useTheme as ChakraTheme } from '@chakra-ui/react';
import { Moon, Sun } from 'lucide-react';
import { useTranslations } from 'next-intl';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
function MobileTheme() {
  const [mounted, setMounted] = useState(false);
  const {
    theme,
    setTheme
  } = useTheme();
  const {
    setColorMode
  } = useColorMode();
  const t = useTranslations();
  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    setColorMode(theme);
  }, [theme]);
  if (!mounted) {
    return null;
  }
  const chakraTheme = ChakraTheme();
  return <Flex w="100%" direction="column" mb="4" data-sentry-element="Flex" data-sentry-component="MobileTheme" data-sentry-source-file="Theme.tsx">
      <Flex w="100%" mb="2" align="center" justify="flex-start" data-sentry-element="Flex" data-sentry-source-file="Theme.tsx">
        <Text mx="2" data-sentry-element="Text" data-sentry-source-file="Theme.tsx">{t('Theme')} </Text>
        {theme === 'light' ? <Sun size={16} color={chakraTheme.colors.text[100]} /> : <Moon size={16} color={chakraTheme.colors.text[100]} />}
      </Flex>

      <Flex gap={2} w="full" borderRadius="12px" border="1px" borderColor="darkborder" data-sentry-element="Flex" data-sentry-source-file="Theme.tsx">
        <Button w="full" variant="none" fontWeight="500" isActive={theme === 'light'} onClick={() => setTheme('light')} leftIcon={<Sun size={16} color={chakraTheme.colors.text[100]} />} data-sentry-element="Button" data-sentry-source-file="Theme.tsx">
          <Text color="text.100" data-sentry-element="Text" data-sentry-source-file="Theme.tsx">{t('Light')}</Text>
        </Button>
        <Button w="full" fontWeight="500" variant="none" isActive={theme === 'dark'} onClick={() => setTheme('dark')} leftIcon={<Moon size={16} color={chakraTheme.colors.text[100]} />} data-sentry-element="Button" data-sentry-source-file="Theme.tsx">
          <Text color="text.100" data-sentry-element="Text" data-sentry-source-file="Theme.tsx">{t('Dark')}</Text>
        </Button>
      </Flex>
    </Flex>;
}
export default MobileTheme;