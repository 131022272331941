'use client';

import { Box, Text, Flex } from '@chakra-ui/react';
import { MenuIcon, ChevronLeft } from 'lucide-react';
import { useLocale, useTranslations } from 'next-intl';
import MobileTheme from './Theme';
import MobileLanguage from './MobileLanguage';
function DisplaySettings() {
  const t = useTranslations();
  return <>
      <Box p="3" mt="2" data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <Text fontSize="20px" fontWeight="500" mb={1} data-sentry-element="Text" data-sentry-source-file="index.tsx">
          {t('Display Settings')}
        </Text>
        <Box p="4" bg="alphaGradient.100" boxShadow="popover.xs" borderRadius="12px" data-sentry-element="Box" data-sentry-source-file="index.tsx">
          <MobileTheme data-sentry-element="MobileTheme" data-sentry-source-file="index.tsx" />
          <MobileLanguage data-sentry-element="MobileLanguage" data-sentry-source-file="index.tsx" />
        </Box>
      </Box>
    </>;
}
export default DisplaySettings;